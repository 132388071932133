import React, { useEffect, useState } from "react";
import Hkvision from "./components/Hkvision";
import styled from "styled-components";
import { decode as atob } from "base-64";

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [deviceSerial, setDeviceSerial] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("AT");
    const serial = urlParams.get("DS");
    const verifyCode = urlParams.get("VC");
    setAccessToken(atob(token));
    setDeviceSerial(atob(serial));
    setVerificationCode(atob(verifyCode));
  }, []);

  return (
    <MainContainer>
      {deviceSerial && accessToken ? (
        <Hkvision
          url={`ezopen://${verificationCode}@open.ezviz.com/${deviceSerial}/1.hd.live`}
          accessToken={accessToken}
        />
      ) : null}
    </MainContainer>
  );
}

export default App;

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;
