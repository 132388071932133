import React, { useEffect, useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
function Hkvision({ url, accessToken }) {
  console.log(url, accessToken);
  const [paused, setPaused] = useState(false);
  const [expanded] = useState(false);
  const [recording, setRecording] = useState(false);

  const isChrome = true;
  // !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const decoder = useRef(0);
  function initPlay() {
    decoder.current = new window.EZUIKit.EZUIPlayer({
      id: "playWind",
      autoplay: true,
      url: url,
      width: document.getElementById("playWind").offsetWidth,
      height: document.getElementById("playWind").offsetHeight,
      accessToken: accessToken,
      decoderPath: "",
      handleError: handleError,
      handleSuccess: handleSuccess,
      env: {
        domain: "https://isgpopen.ezvizlife.com",
      },
    });
  }
  function handleError(e) {
    console.log("catch error", e);
  }
  function handleSuccess() {
    console.log("play success");
  }

  const initPlayCallback = useCallback(initPlay, [expanded]);

  useEffect(() => {
    initPlayCallback();
  }, [initPlayCallback]);

  function onPressPause() {
    const stopPromise = decoder.current.stop();
    stopPromise.then(() => {
      setPaused(true);
    });
  }

  function onPressPlay() {
    initPlay(decoder.current);
    setPaused(false);
  }

  function onPressScreenShot() {
    const capturePicturePromise = decoder.current.capturePicture(0, "default");
    capturePicturePromise.then((data) => {
      console.log("Captured");
    });
  }

  function onPressRecordStart() {
    const startSavePromise = decoder.current.startSave(
      0,
      new Date().getTime() + "video"
    );
    startSavePromise
      .then(() => {
        console.log("start save success", startSavePromise);
        setRecording(true);
      })
      .catch((error) => {
        console.log("start Save error", error);
      });
  }

  function onPressRecordStop() {
    const stopSavePromise = decoder.current.stopSave(0);
    stopSavePromise
      .then(() => {
        console.log("stop save success", stopSavePromise);
        setRecording(false);
      })
      .catch((error) => {
        console.log("stop Save error", error);
      });
  }

  return (
    <CameraWindowContainer expanded={expanded}>
      <PlayWindow
        id="playWind"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "16px !important",
        }}
      />
      <Actions className={"actions"} expanded={expanded}>
        {paused ? (
          <button className={"ripple"} onClick={() => onPressPlay()}>
            <img
              alt={"play"}
              src={require("../assets/icons/play-button.svg")}
            />
          </button>
        ) : (
          <button className={"ripple"} onClick={() => onPressPause()}>
            <img alt={"pause"} src={require("../assets/icons/pause.svg")} />
          </button>
        )}
        <button className={"ripple"} onClick={() => onPressScreenShot()}>
          <img
            alt={"ar-camera"}
            src={require("../assets/icons/ar-camera.svg")}
          />
        </button>
        {recording && isChrome ? (
          <div className={"btn-text-container"}>
            <button className={"ripple"} onClick={() => onPressRecordStop()}>
              <img alt={"stop"} src={require("../assets/icons/stop.svg")} />
            </button>
            <p>Recording</p>
          </div>
        ) : isChrome ? (
          <button className={"ripple"} onClick={() => onPressRecordStart()}>
            <img alt={"record"} src={require("../assets/icons/record.svg")} />
          </button>
        ) : null}

        <button
          className={"ripple"}
          onClick={() => decoder.current.fullScreen()}
        >
          <img alt={"expand"} src={require("../assets/icons/expand.svg")} />
        </button>
      </Actions>
    </CameraWindowContainer>
  );
}

export default Hkvision;

const CameraWindowContainer = styled.div`
  width: ${({ expanded }) => (expanded ? "100%" : "45%")};
  height: ${({ expanded }) => (expanded ? "100%" : "50%")};
  background-color: black;
  position: relative;
  @media (max-width: 1500px) {
    width: ${({ expanded }) => (expanded ? "100%" : "75%")};
    height: ${({ expanded }) => (expanded ? "100%" : "60%")};
  }
  @media (max-width: 900px) {
    height: ${({ expanded }) => (expanded ? "100%" : "40%")};
  }
  @media (max-width: 620px) {
    width: ${({ expanded }) => (expanded ? "100%" : "85%")};
    height: ${({ expanded }) => (expanded ? "100%" : "30%")};
  }
  @media (max-width: 480px) {
    width: ${({ expanded }) => (expanded ? "100%" : "95%")};
    height: ${({ expanded }) => (expanded ? "100%" : "30%")};
  }
  @media only screen and (max-width: 620px) and (orientation: landscape) {
    transform: rotate(90deg);
  }
`;

const PlayWindow = styled.div`
  .parent-wnd {
    div {
      background-color: black !important;
    }
  }
`;

const Actions = styled.div`
  width: 100%;
  height: 50px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${({ expanded }) =>
    expanded &&
    css`
      position: absolute;
    `}

  @media (max-width: 620px) {
    height: 30px;
  }

  .ripple {
    background-position: center;
    transition: background 0.8s;
  }
  .btn-text-container {
    display: flex;
    align-items: center;
    p {
      color: crimson;
      opacity: 0.8;
    }
  }
  .ripple:hover {
    background: rgb(114, 62, 216)
      radial-gradient(circle, transparent 1%, rgb(114, 62, 216) 1%)
      center/15000%;
    box-shadow: 0 0 4px rgb(114, 62, 216);
  }
  .ripple:active {
    background-color: white;
    background-size: 100%;
    transition: background 0s;
  }

  button {
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 16px;
    color: white;
    outline: none;
    background-color: transparent;
    @media (max-width: 620px) {
      width: 22px;
      height: 22px;
    }
    img {
      position: absolute;
      height: 18px;
      width: 18px;
      object-fit: contain;
      @media (max-width: 620px) {
        height: 10px;
        width: 10px;
      }
    }
  }
`;
